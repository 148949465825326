<template>
  <div style="height:100%">
    <production-log-loading v-if="loading" />
    <production-log-setup v-else-if="!loading && (!dataOnboarded || !elementOnboarded)" />
    <template v-else>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      </template>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import ProductionLogSetup from './ProductionLogSetup.vue';
import ProductionLogLoading from './ProductionLogLoading.vue';

export default {
  name: 'ProductionLogIndex',
  components: {
    ProductionLogSetup,
    ProductionLogLoading,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('productionLog', [
      'dataOnboarded',
      'elementOnboarded',
      'lastRefreshedAt',
    ]),
    ...mapState('webApp', ['config', 'storageLocation']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  async created() {
    this.loading = true;
    const config = localStorage.getItem(this.storageLocation.production);
    await this.fetchSingleMachine();
    if (config) {
      this.setConfig(JSON.parse(config));
    } else {
      this.resetConfig();
    }
    const appId = localStorage.getItem('appId');
    await this.getSchemaAndLabel(appId);
    await this.getDataOnboardingState();
    if (this.dataOnboarded) {
      await this.getElementOnboardingState();
      if (this.elementOnboarded) {
        await this.initApp();
      } else {
        await this.getMasterElements();
        const success = await this.createElements();
        if (success) {
          this.setElementOnboarded(true);
        }
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('productionLog', ['setElementOnboarded']),
    ...mapMutations('helper', ['setExtendedHeader']),
    ...mapActions('webApp', ['getAppSchema']),
    ...mapActions('helper', ['getSchemaAndLabel']),
    ...mapActions('productionLog', [
      'getDataOnboardingState',
      'getElementOnboardingState',
      'fetchOperators',
      'fetchRejectionReasons',
      'fetchReworkReasons',
      'fetchScrapReasons',
      'fetchProductionList',
      'createElements',
      'getMasterElements',
      'fetchSingleMachine',
    ]),
    refreshProductions() {
      this.fetchProductionList();
    },
    async initApp() {
      await Promise.all([
        this.getAppSchema(),
        this.fetchOperators(),
        this.fetchRejectionReasons(),
        this.fetchReworkReasons(),
        this.fetchScrapReasons(),
      ]);
      this.setExtendedHeader(true);
    },
    gotToSettings() {
      this.$router.push({ name: 'productionLogSettings' });
    },
  },
  watch: {
    async elementOnboarded(val) {
      if (val) {
        await this.initApp();
      }
    },
    config: {
      deep: true,
      handler(val) {
        localStorage.setItem(this.storageLocation.production, JSON.stringify(val));
      },
    },
  },
};
</script>
