<template>
  <v-btn color="primary" small @click="saveline" :disabled="!saveEnabled">Save </v-btn>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'SelectedLineUpdate',
  data() {
    return {};
  },
  props: {
    // selectedLine: {
    //   type: Object,
    //   required: true,
    // },
    saveEnabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.selectedLineNew = { ...this.selectedLine };
  },
  computed: {
    ...mapState('productionLayoutMes', ['selectedLine', 'stationsbylines']),
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', ['updateLine']),
    async saveline() {
      this.saving = true;
      this.selectedLineNew = { ...this.selectedLine };
      this.newSaveLine = {
        description: this.selectedLine.description,
      };
      let created = false;
      const payload = {
        query: `?query=id=="${this.selectedLine.id}"`,
        payload: this.newSaveLine,
        id: this.selectedLine.id,
      };
      created = this.updateLine(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'LINE_UPDATED',
        });
        this.saveEnabled = false;
        this.dialog = false;
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_UPDATING_LINE',
        });
      }
      this.saving = false;
    },
    async resetDialog() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
