<template>
  <v-dialog persistent v-model="dialog" transition="dialog-transition" max-width="700px">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-text="'$plus'" class="float-right"></v-icon>
    </template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">Add Station</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="
              dialog = false;
              dialogReset();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                label="Select Subline *"
                :items="sublines"
                item-text="name"
                return-object
                v-model="selectedSubLine"
                :rules="selectReqRules"
              >
              </v-select>
              <v-text-field
                label="Name *"
                hint="For example, STATION-01"
                v-model="newStation.name"
                counter="15"
                :rules="nameRules"
                required
                type="text"
              ></v-text-field>
              <v-text-field
                label="Number *"
                type="number"
                min="0"
                hint="For example, 111"
                v-model="newStation.numbers"
                counter="10"
                :rules="numberRules"
                required
              ></v-text-field>
              <v-text-field
                label="Description"
                type="text"
                hint="For example, Station added by Manager"
                v-model="newStation.description"
              ></v-text-field>
              <v-text-field
                label="Expected OEE"
                type="number"
                min="0"
                hint="For example, 42"
                suffix="%"
                v-model="newStation.expectedoee"
              ></v-text-field>
              <v-text-field
                label="Expected Cycletime"
                type="number"
                hint="For example, 45"
                min="0"
                suffix="sec"
                v-model="newStation.expectedcycletime"
              ></v-text-field>
              <v-text-field
                label="Manufacturing Date *"
                type="date"
                hint="select Date"
                v-model="newStation.manufacturingdate"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Weight"
                type="number"
                min="0"
                suffix="kg"
                v-model="newStation.weight"
                hint="For example, 36"
              ></v-text-field>
              <v-text-field
                label="Size"
                type="number"
                min="0"
                suffix="m"
                v-model="newStation.size"
                hint="For example, 40"
                dense
              ></v-text-field>
              <v-text-field
                label="Voltage"
                type="number"
                min="0"
                suffix="V"
                v-model="newStation.voltage"
                hint="For example, 49"
                dense
              ></v-text-field>
              <v-text-field
                label="Power"
                type="number"
                min="0"
                suffix="kW"
                v-model="newStation.power"
                hint="For example, 42"
                dense
              ></v-text-field>
              <v-text-field
                label="Supplier"
                type="text"
                v-model="newStation.supplier"
                hint="For example, Entrib Analytics Solution"
                dense
              ></v-text-field>
              <v-text-field
                label="Lifetime"
                type="text"
                min="0"
                suffix="y"
                v-model="newStation.lifetime"
                hint="For example, 4"
                dense
              ></v-text-field>
              <v-spacer></v-spacer>
              <!-- <v-text-field label="Process" type="text"
              v-model="newStation.process"
              hint="For example, Night Shift"  dense></v-text-field> -->
              <v-text-field
                label="Endpoint URL"
                class="mb-3"
                hint="Hint: opc.tcp://127.0.0.1:1010"
                v-model="newStation.endpointurl"
                :rules="endpointUrlRules"
                type="text"
                dense
              ></v-text-field>
              <v-text-field
                label="Device IP Address"
                :rules="plcRules"
                class="mb-3"
                hint="Hint: 127.168.1.1"
                v-model="newStation.plcipaddress"
                dense
              ></v-text-field>
              <v-text-field
                label="Usage Start Date *"
                type="date"
                v-model="newStation.usagestartdate"
                :disabled="usgDateDisabled"
                return-object
                hint="Select a Date greater than the Manfacturing Date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn> -->
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!valid"
            :loading="saving"
            @click="saveStation"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      stationNew: {},
      usgDateDisabled: true,
      newStation: {},
      selectedSubLine: null,
      default: false,
      dialog: false,
      valid: true,
      name: '',
      numbers: '',
      plcipaddress: '',
      saving: false,
      selectReqRules: [
        (v) => !!v || 'Selection required',
      ],
      numberRules: [
        (v) => (v && v.length) > 0 || 'Number required',
        (v) => (v && v.length <= 10) || 'Number must be less than 10 characters',
      ],
      endpointUrlRules: [
        (v) => (!v || /^([a-z.]*):\/\/([a-zA-Z_\-.\-0-9]*)(:[0-9]{1,5})(\/.*){0,1}/.test(v))
          || 'Invalid Endpoint URL',
      ],
      nameRules: [
        (v) => !!v || 'Name required',
        (v) => (v && v.length <= 15) || 'Name must be less than 15 characters',
        (v) => /^\S+$/.test(v) || 'Spaces not allowed',
      ],
      plcRules: [
        (v) => (!v || /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/.test(v))
          || 'Invalid IP address',
      ],
    };
  },
  props: {
    lineid: {
      type: String,
      required: true,
    },
  },
  created() {
  },
  computed: {
    ...mapState('productionLayoutMes', ['stations', 'sublines', 'allSublines', 'assetId']),
  },
  watch: {
    newStation: {
      handler(val) {
        if (val.manufacturingdate === undefined || !val.manufacturingdate) {
          this.usgDateDisabled = true;
        } else {
          this.usgDateDisabled = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'createSubline',
      'getAllSublines',
      'createStation',
    ]),
    async saveStation() {
      this.$refs.form.validate();
      const stationNameFlag = this.stations.filter(
        (o) => o.name.toLowerCase().split(' ').join('') === this.newStation.name.toLowerCase().split(' ').join(''),
      );
      const stationNumberFlag = this.stations.filter(
        (o) => o.numbers === parseInt(this.newStation.numbers, 10),
      );
      const fromdate = this.newStation.manufacturingdate;
      const todate = this.newStation.usagestartdate;
      const from = new Date(fromdate);
      const to = new Date(todate);
      if (to < from) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'GREATER_DATE',
        });
      } else if (stationNumberFlag.length > 0) {
        this.newStation.numbers = '';
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST_NO',
        });
      } else if (stationNameFlag.length > 0) {
        this.newStation.name = '';
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST',
        });
      } else if (!(this.newStation.endpointurl || this.newStation.plcipaddress)) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'NO_CONNECT_INFO',
        });
      } else {
        this.saving = true;
        this.stationNew = {
          ...this.newStation,
          lineid: this.lineid,
          sublineid: this.selectedSubLine.id,
          linename: this.selectedSubLine.linename,
          sublinename: this.selectedSubLine.name,
          assetid: this.assetId,
        };
        let created = false;
        if (this.stationNew.usagestartdate) {
          this.stationNew.usagestartdate = `${this.stationNew.usagestartdate
            .split('-')
            .reverse()
            .join('-')}:00:00:00`;
        }
        const payload = this.stationNew;
        created = await this.createStation(payload);
        if (created) {
          this.setAlert({
            show: true,
            type: 'success',
            message: 'STATION_CREATED',
          });
          this.saving = false;
          this.dialog = false;
          this.stationNew = {};
          this.selectedSubLine = null;
          this.newStation = {};
          this.$refs.form.resetValidation();
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_CREATING_STATION',
          });
        }
        this.saving = false;
      }
    },
    async dialogReset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
