<template>
  <div>
    <column-config v-if="id === 'columns'" />
  </div>
</template>

<script>
import ColumnConfig from './ColumnConfig.vue';

export default {
  name: 'AdminWindow',
  components: {
    ColumnConfig,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
