<template>
  <v-dialog v-model="dialog" max-width="290">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-text="'$delete'" class="float-right" color="error"></v-icon>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Delete Station?
        <v-spacer></v-spacer>
        <v-btn icon small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text> Are you sure? </v-card-text>
      <v-card-text>
        This also removes all associated roadmaps and orders!
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="text-none" @click="btnDeleteStation" :loading="deleting">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      plan: {},
      // assetId: 4,
      default: false,
      dialog: false,
      deleting: false,
    };
  },
  props: {
    station: {
      type: Object,
      required: true,
    },
    subline: {
      type: Object,
      required: false,
    },
  },
  created() {
  },
  computed: {
    ...mapState('productionLayoutMes', ['runningOrderList', 'roadMapDetailsRecord', 'subStations']),
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'deleteStation',
      'getRunningOrder',
      'getRoadMapDetailsRecord',
      'getSubStationIdElement',
      'getSubStations',
      'inactiveElement',
    ]),
    async btnDeleteStation() {
      let compareStation = [];
      await this.getRoadMapDetailsRecord();
      await this.getRunningOrder();
      if (this.runningOrderList) {
        const getroadMapId = this.runningOrderList.map((rmi) => rmi.roadmapid);
        const roadmapList = this.roadMapDetailsRecord.filter(
          (rm) => rm.roadmapid === getroadMapId[0],
        );
        compareStation = roadmapList.filter((s) => s.sublineid === this.subline.id);
      }
      if (compareStation.length) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'STATION_HAS_RUNNING_ORDER',
        });
        this.dialog = false;
      } else {
        // get plc parameter
        // const getSubStation = this.subStations
        //   .filter((s) => s.stationid === this.station.id);
        // const matchSubStation = getSubStation.forEach(async (item) => {
        //   const element = await this.getSubStationIdElement(item.id);
        //   const eleList = await this.inactiveElement(
        //     {
        //       elementId: element.id,
        //       status: 'INACTIVE',
        //     },
        //   );
        //   // if (eleList) {
        //   //   this.setAlert({
        //   //     show: true,
        //   //     type: 'success',
        //   //     message: 'INACTIVE_PROCESS_ELEMENT',
        //   //   });
        //   // }
        // });
        // await Promise.all([matchSubStation]);
        const gotRoadmap = this.roadMapDetailsRecord.filter((s) => s.stationid === this.station.id);
        const stationObject = {
          id: this.station.id,
          lineid: this.station.lineid,
          sublineid: this.station.sublineid,
          roadmapid: null,
        };
        if (gotRoadmap.length > 0) {
          const deleteRoadmapId = gotRoadmap[0].roadmapid;
          stationObject.roadmapid = deleteRoadmapId;
        }
        let deleted = false;
        this.deleting = true;
        deleted = await this.deleteStation(stationObject);
        if (deleted) {
          this.setAlert({
            show: true,
            type: 'success',
            message: 'STATION_AND_DEPENDANT_DELETED',
          });
          this.deleting = false;
          this.dialog = false;
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_DELETING_STATION',
          });
        }
      }
    },
  },
};
</script>
