export default {
  element: {
    categoryType: 'ASSET',
    collectionName: 'default',
    elementName: '',
    elementDescription: '',
    status: 'ACTIVE',
    elementType: 'DEFAULT',
    uniqueTagName: '',
    uniqueTagValue: 0,
    uniqueTagStartValue: 0,
    uniqueTagValuePrefix: '',
    uniqueTagValueSuffix: '',
    businessTimeTagsRequired: false,
    optional: false,
    assetBased: true,
    uniqueTag: false,
  },
  tags: [
    {
      tagName: 'createdTimestamp',
      emgTagType: 'Date',
      tagDescription: 'Created At',
      assetId: 0,
      status: 'ACTIVE',
      derivedField: false,
      derivedFunctionName: null,
      derivedFieldType: null,
      filter: false,
      filterFromElementName: null,
      filterFromTagName: null,
      required: true,
      hide: false,
      tagOrder: 1,
      filterFromList: null,
    },
    {
      tagName: 'modifiedTimestamp',
      emgTagType: 'Date',
      tagDescription: 'Modified At',
      assetId: 0,
      status: 'ACTIVE',
      derivedField: false,
      derivedFunctionName: null,
      derivedFieldType: null,
      filter: false,
      filterFromElementName: null,
      filterFromTagName: null,
      required: true,
      hide: false,
      tagOrder: 2,
      filterFromList: null,
    },
    {
      tagName: 'timestamp',
      emgTagType: 'Long',
      tagDescription: 'Read at',
      assetId: 0,
      status: 'ACTIVE',
      derivedField: false,
      derivedFunctionName: null,
      derivedFieldType: null,
      filter: false,
      filterFromElementName: null,
      filterFromTagName: null,
      required: true,
      hide: false,
      tagOrder: 3,
      filterFromList: null,
    },
  ],
};
