<template>
  <div style="height:100%">
    <portal to="app-header">
      <span>{{ $t('production.title') }}</span>
      <v-btn icon small class="ml-4 mb-1">
        <v-icon
          v-text="'$info'"
        ></v-icon>
      </v-btn>
      <v-tooltip bottom v-if="!isMobile">
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            class="ml-2 mb-1"
            @click="gotToSettings"
          >
            <v-icon
              v-text="'$settings'"
            ></v-icon>
          </v-btn>
        </template>
        {{ $t('production.settingsTitle') }}
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            class="ml-2 mb-1"
            @click="refreshProductions"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ $t('production.lastRefreshed') }} <strong>{{ lastRefreshedAt }}</strong>
      </v-tooltip>
    </portal>
    <production-toolbar />
    <production-drawer />
    <production-list />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import ProductionToolbar from '../components/ProductionToolbar.vue';
import ProductionDrawer from '../components/ProductionDrawer.vue';
import ProductionList from '../components/ProductionList.vue';

export default {
  name: 'ProductionLog',
  components: {
    ProductionToolbar,
    ProductionDrawer,
    ProductionList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('productionLog', [
      'dataOnboarded',
      'elementOnboarded',
      'lastRefreshedAt',
    ]),
    ...mapState('webApp', ['config', 'storageLocation']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  async created() {
    this.setExtendedHeader(true);
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('productionLog', ['setElementOnboarded']),
    ...mapMutations('helper', ['setExtendedHeader']),
    ...mapActions('helper', ['getSchemaAndLabel']),
    ...mapActions('productionLog', [
      'getDataOnboardingState',
      'getElementOnboardingState',
      'fetchOperators',
      'fetchRejectionReasons',
      'fetchReworkReasons',
      'fetchScrapReasons',
      'fetchProductionList',
      'createElements',
      'getMasterElements',
    ]),
    refreshProductions() {
      this.fetchProductionList();
    },
    gotToSettings() {
      this.$router.push({ name: 'productionLogSettings' });
    },
  },
};
</script>
