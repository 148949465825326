<template>
<v-dialog
 persistent
 v-model="dialog"
 transition="dialog-transition"
 max-width="700px">
   <template #activator="{ on }">
    <v-btn
      v-on="on"
      small
      color="primary"
      class="text-none ml-2"
    >
    <v-icon small left>mdi-plus</v-icon>
    Add new Line
    </v-btn>
    </template>
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-card>
    <v-card-title>
        <span class="headline">Add Line</span>
         <v-spacer></v-spacer>
        <v-btn icon small @click="(dialog = false); dialogReset();">
          <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-card-title>
    <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field label="Line Name"
              v-model="name"
              :rules="rule.lineNameRule"
              :counter="15" required>
              </v-text-field>
              <v-text-field label="Line Description"
              v-model="description">
              </v-text-field>
            </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"
        class="text-none"
        :disabled="!valid"
        :loading="saving"
         @click="saveLine">Save</v-btn>
    </v-card-actions>
    </v-card>
    </v-form>
</v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      name: '',
      description: '',
      valid: true,
      saving: false,
      rule: {
        lineNameRule: [(v) => !!v || 'Name required',
          (v) => (v && v.length <= 15) || 'Name must be less than 15 characters',
          (v) => (v && !this.names.includes(v.toLowerCase().split(' ').join(''))) || 'Name is already in use',
          (v) => /^\S+$/.test(v) || 'Spaces not allowed'],
      },
    };
  },
  props: {
  },
  created() {
    this.getLines();
  },
  computed: {
    ...mapState('productionLayoutMes', ['selectedLine', 'assetId', 'lines']),
    names() {
      return this.lines.map((s) => s.name.toLowerCase().split(' ').join(''));
    },
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes',
      ['createLine', 'getLines']),
    async saveLine() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.newProcess = {
          name: this.name,
          description: this.description,
          assetid: this.assetId,
        };
        let created = false;
        const payload = this.newProcess;
        created = this.createLine(payload);
        if (created) {
          this.setAlert({
            show: true,
            type: 'success',
            message: 'LINE_CREATED',
          });
          this.dialog = false;
          this.name = null;
          this.description = null;
          this.$refs.form.resetValidation();
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_CREATING_LINE',
          });
        }
        this.saving = false;
      }
    },
    async dialogReset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>
