<template>
  <v-dialog persistent v-model="dialog" transition="dialog-transition" max-width="600px">
    <template #activator="{ on }">
      <v-icon v-on="on" v-text="'$plus'" class="float-right"></v-icon>
    </template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">Add Substation</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="
              dialog = false;
              dialogReset();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            label="Select Station *"
            :items="stationsbylines"
            item-text="name"
            return-object
            v-model="selectedSubstationLine"
            @click="getfilteredStations"
            :rules="selectReqRules"
          >
          </v-select>
          <v-text-field
            label="Name *"
            hint="For example, SST-01"
            v-model="newSubstation.name"
            :rules="nameRules"
            counter="15"
            required
          >
          </v-text-field>
          <v-text-field
            label="Number *"
            type="number"
            min="0"
            hint="For example, 1111"
            v-model="newSubstation.numbers"
            :rules="numberRules"
            counter="10"
            required
          >
          </v-text-field>
          <v-text-field
            label="Description"
            hint="For example, added by Manager"
            type="text"
            v-model="newSubstation.description"
          >
          </v-text-field>
          <v-textarea
          dense
          hint="Paste JSON here"
          rows="3"
          outlined
          single-line
          v-model="newSubstation.jsondata"
          label="Line Management Configuration"
          :rules="configRules">
          </v-textarea>
          <v-switch
          v-model="newSubstation.serverlive"
          label="Write Server Live Bit"
          ></v-switch>
          <v-switch
            v-model="newSubstation.initialsubstation"
            label="Initial Sub Station"
            :disabled="btnInitdisable"
          >
          </v-switch>
          <v-switch
            v-model="newSubstation.finalsubstation"
            label="Final Sub Station"
            :disabled="btnFindisable"
          >
          </v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!valid"
            :loading="saving"
            @click="saveSubstation"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import elementsAndTags from './data/element';

export default {
  data() {
    return {
      newSubstation: {},
      // assetId: null,
      selectedSubstationLine: null,
      default: false,
      dialog: false,
      btnInitdisable: false,
      btnFindisable: false,
      valid: true,
      name: '',
      numbers: '',
      saving: false,
      selectReqRules: [
        (v) => !!v || 'Selection required',
      ],
      configRules: [
        (v) => (!v || this.isValidJsonString(v)) || 'Input valid JSON configuration.',
      ],
      numberRules: [
        (v) => (v && v.length) > 0 || 'Number required',
        (v) => (v && v.length <= 10) || 'Number must be less than 10 characters',
      ],
      nameRules: [
        (v) => !!v || 'Name required',
        (v) => (v && v.length <= 15) || 'Name must be less than 10 characters',
        (v) => /^\S+$/.test(v) || 'Spaces not allowed',
      ],
    };
  },
  props: {
    lineid: {
      type: [Number, String],
      required: true,
    },
  },
  created() {
    // this.newSubstation = { ...this.substation };
  },
  computed: {
    ...mapState('productionLayoutMes', [
      'selectedLine',
      'stationsbylines',
      'sublines',
      'allStations',
      'stations',
      'subStations',
      'assetId',
    ]),
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'createSubstation',
      'getStationbyline',
      'createElementsandTags',
    ]),
    compareValues(val) {
      if (val.initialsubstation === true) {
        this.btnFindisable = true;
      } else {
        this.btnFindisable = false;
      }
      if (val.finalsubstation === true) {
        this.btnInitdisable = true;
      } else {
        this.btnInitdisable = false;
      }
    },
    isValidJsonString(jsonString) {
      if (!(jsonString && typeof jsonString === 'string')) {
        return false;
      }
      try {
        JSON.parse(jsonString);
        return true;
      } catch (error) {
        return false;
      }
    },
    async getfilteredStations() {
      this.getStationbyline(`?query=lineid==${this.selectedLine.id}`);
    },
    async saveSubstation() {
      this.$refs.form.validate();
      const initialSubstationFlag = this.subStations.filter(
        (item) => item.sublineid === this.selectedSubstationLine.sublineid
            && item.initialsubstation === true
            && item.initialsubstation === this.newSubstation.initialsubstation,
      );
      const finalSubstationFlag = this.subStations.filter(
        (item) => item.sublineid === this.selectedSubstationLine.sublineid
            && item.finalsubstation === true
            && item.finalsubstation === this.newSubstation.finalsubstation,
      );
      const substationNumberFlag = this.subStations.filter(
        (o) => o.numbers === parseInt(this.newSubstation.numbers, 10),
      );
      const substationNameFlag = this.subStations.filter(
        (o) => o.name
          .toLowerCase()
          .split(' ')
          .join('')
            === this.newSubstation.name
              .toLowerCase()
              .split(' ')
              .join(''),
      );
      if (initialSubstationFlag.length > 0) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST_INITIAL_SUB-STATION',
        });
      } else if (finalSubstationFlag.length > 0) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST_FINAL_SUB-STATION',
        });
      } else if (substationNumberFlag.length > 0) {
        this.newSubstation.numbers = '';
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST_NO',
        });
      } else if (substationNameFlag.length > 0) {
        this.newSubstation.name = '';
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ALREADY_EXSIST',
        });
      } else {
        this.saving = true;
        this.newSubstation = {
          ...this.newSubstation,
          stationid: this.selectedSubstationLine.id,
          sublineid: this.selectedSubstationLine.sublineid,
          ismainline: this.sublines.filter(
            (f) => f.id === this.selectedSubstationLine.sublineid,
          )[0].ismainline,
          lineid: this.selectedSubstationLine.lineid,
          linename: this.selectedSubstationLine.linename,
          sublinename: this.selectedSubstationLine.sublinename,
          stationname: this.selectedSubstationLine.name,
          assetid: this.assetId,
        };
        let created = false;
        const payload = this.newSubstation;
        created = await this.createSubstation(payload);
        if (created) {
          this.setAlert({
            show: true,
            type: 'success',
            message: 'SUB-STATION_CREATED',
          });
          const substationid = this.subStations[0].id;
          const substationDescription = this.subStations[0].name;
          const elementsToADD = [];
          const { element, tags } = elementsAndTags;
          let elementProperties = { ...element };
          elementProperties.elementName = `process_${substationid}`;
          elementProperties.elementDescription = `Process_${substationDescription}`;
          elementsToADD.push({
            element: elementProperties,
            tags,
          });
          elementProperties = { ...element };
          elementProperties.elementName = `production_${substationid}`;
          elementProperties.elementDescription = `Production_${substationDescription}`;
          elementsToADD.push({
            element: elementProperties,
            tags,
          });
          await this.createElementsandTags(elementsToADD);
          this.setAlert({
            show: true,
            type: 'success',
            message: 'ELEMENTS_SUB-STATION_CREATED',
          });
          this.newSubstation = {};
          this.$refs.form.reset();
          this.saving = false;
          this.dialog = false;
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_CREATING_SUB-STATION',
          });
        }
        this.saving = false;
      }
    },
    async dialogReset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    newSubstation: {
      handler(val) {
        this.compareValues(val);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
