<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on }">
      <!-- <v-btn color="primary" dark v-on="on">Open Dialog</v-btn> -->
      <v-icon v-on="on" v-text="'$edit'" color="primary" class="float-right"></v-icon>
    </template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">Update Station</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="
              dialog = false;
              resetDialog();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Name *"
                v-model="newStation.name"
                :rules="nameRules"
                counter="15"
                required
                hint="For example, STATION-01"
                @keyup="nameValid"
              ></v-text-field>
              <v-text-field
                label="Number *"
                type="number"
                min="0"
                v-model="newStation.numbers"
                :rules="numberRules"
                counter="10"
                required
                hint="For example, 111"
                @keyup="numberValid"
              ></v-text-field>
              <v-text-field
                label="Description"
                type="text"
                hint="For example, Updated by Manager"
                v-model="newStation.description"
              ></v-text-field>
              <v-text-field
                label="Expected OEE"
                type="number"
                min="0"
                hint="For example, 42"
                suffix="%"
                v-model="newStation.expectedoee"
              ></v-text-field>
              <v-text-field
                label="Expected Cycletime"
                type="number"
                hint="For example, 45"
                min="0"
                suffix="sec"
                v-model="newStation.expectedcycletime"
              ></v-text-field>
              <v-text-field
                label="Manufacturing Date"
                type="date"
                hint="select Date"
                v-model="newStation.manufacturingdate"
                @input="comapareDates"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Weight"
                type="number"
                min="0"
                suffix="kg"
                v-model="newStation.weight"
                hint="For example, 36"
                dense
              ></v-text-field>
              <v-text-field
                label="Size"
                type="number"
                min="0"
                suffix="m"
                v-model="newStation.size"
                hint="For example, 40"
                dense
              ></v-text-field>
              <v-text-field
                label="Voltage"
                type="number"
                min="0"
                suffix="V"
                v-model="newStation.voltage"
                hint="For example, 49"
                dense
              ></v-text-field>
              <v-text-field
                label="Power"
                type="Description"
                min="0"
                suffix="kW"
                v-model="newStation.power"
                hint="For example, 42"
                dense
              ></v-text-field>
              <v-text-field
                label="Supplier"
                type="text"
                v-model="newStation.supplier"
                hint="For example, Entrib Analytics Solution"
                dense
              ></v-text-field>
              <v-text-field
                label="Life time"
                type="text"
                min="0"
                suffix="y"
                v-model="newStation.lifetime"
                hint="For example, 4"
                dense
              ></v-text-field>
              <!-- <v-text-field label="Process" type="text"
        v-model="newStation.process"  dense></v-text-field> -->
              <v-text-field
                label="Endpoint Url"
                class="mb-3"
                hint="Hint: opc.tcp://127.0.0.1:1010"
                v-model="newStation.endpointurl"
                :rules="endpointUrlRules"
                type="text"
                dense
                @change="endpointChanged = true"
              ></v-text-field>
              <v-text-field
                label="Device IP Address"
                :rules="plcRules"
                class="mb-3"
                hint="Hint: 127.168.1.1"
                v-model="newStation.plcipaddress"
                @change="ipChanged = true"
                dense
              ></v-text-field>
              <v-text-field
                label="Usage Start Date *"
                type="date"
                v-model="newStation.usagestartdate"
                :disabled="usgDateDisabled"
                @input="comapareDates"
                hint="Select a Date greater than the Manfacturing Date"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            :disabled="btnDisable || !validTodate"
            @click="saveStation"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  props: {
    station: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      newStation: {},
      btnDisable: false,
      valid: true,
      usgDateDisabled: true,
      validTodate: true,
      name: '',
      numbers: '',
      plcipaddress: '',
      endpointChanged: false,
      ipChanged: false,
      numberRules: [
        (v) => (v && v.length) > 0 || 'Number required',
        (v) => (v && v.length <= 10) || 'Number must be less than 10 characters',
      ],
      endpointUrlRules: [
        (v) => (!v || /^([a-z.]*):\/\/([a-zA-Z_\-.\-0-9]*)(:[0-9]{1,5})(\/.*){0,1}/.test(v))
          || 'Invalid Endpoint URL',
      ],
      nameRules: [
        (v) => !!v || 'Name required',
        (v) => (v && v.length <= 15) || 'Name must be less than 15 characters',
        (v) => /^\S+$/.test(v) || 'Spaces not allowed',
      ],
      plcRules: [
        (v) => (!v || /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/.test(v))
          || 'Invalid IP address',
      ],
    };
  },
  created() {
    this.newStation = { ...this.station };
    this.endpointChanged = false;
    this.ipChanged = false;
    if (this.newStation.usagestartdate) {
      this.newStation.usagestartdate = this.newStation.usagestartdate
        .split(':')[0]
        .split('-')
        .reverse()
        .join('-');
    }
  },
  watch: {
    newStation: {
      handler(val) {
        if (val.manufacturingdate === undefined || !val.manufacturingdate) {
          this.usgDateDisabled = true;
        } else {
          this.usgDateDisabled = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('productionLayoutMes', ['stations', 'subStationsForIP']),
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'updateStation',
      'updateIpAddressForParameters',
      'updateSubstation',
      'updateProcess',
    ]),
    async comapareDates() {
      const fromdate = this.newStation.manufacturingdate;
      const todate = this.newStation.usagestartdate;
      const from = new Date(fromdate);
      const to = new Date(todate);
      if (to < from) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'GREATER_DATE',
        });
        this.validTodate = false;
      } else {
        this.validTodate = true;
      }
    },
    async nameValid() {
      if (this.newStation.name === '' || this.newStation.name.length > 15) {
        this.btnDisable = true;
      } else {
        const stationNameFlag = this.stations.filter(
          (o) => o.name
            .toLowerCase()
            .split(' ')
            .join('')
            === this.newStation.name
              .toLowerCase()
              .split(' ')
              .join(''),
        );
        if (stationNameFlag.length > 0) {
          this.btnDisable = true;
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ALREADY_EXSIST',
          });
        } else {
          this.btnDisable = false;
        }
      }
    },
    async numberValid() {
      if (this.newStation.numbers === '' || this.newStation.numbers.length > 10) {
        this.btnDisable = true;
      } else {
        const stationNumberFlag = this.stations.filter(
          (o) => o.numbers === parseInt(this.newStation.numbers, 10),
        );
        if (stationNumberFlag.length > 0) {
          this.btnDisable = true;
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ALREADY_EXSIST_NO',
          });
        } else {
          this.btnDisable = false;
        }
      }
    },
    async saveStation() {
      if (this.newStation.manufacturingdate && !this.newStation.usagestartdate) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'SELECT_TO_DATE',
        });
        this.validTodate = false;
      } else {
        this.saving = true;
        let created = false;
        const payload = {
          query: `?query=lineid==${this.station.lineid}%26%26id=="${this.station.id}"`,
          payload: this.newStation,
          stationid: this.station.stationid,
        };
        created = this.updateStation(payload);
        if (created) {
          payload.query = `?query=stationname=="${this.station.name}"`;
          payload.payload = {
            stationname: this.newStation.name,
          };
          await this.updateSubstation(payload);
          await this.updateProcess(payload);
          this.setAlert({
            show: true,
            type: 'success',
            message: 'STATION_UPDATED',
          });
          this.dialog = false;
          const query = `?query=lineid==${this.station.lineid}%26%26stationid=="${this.station.id}"`;
          if (this.ipChanged) {
            const object = {
              query: `${query}%26%26protocol!="OPCUA"`,
              payload: {
                plcaddress: this.newStation.plcipaddress,
              },
            };
            await this.updateIpAddressForParameters(object);
          }
          if (this.endpointChanged) {
            const object = {
              query: `${query}%26%26protocol=="OPCUA"`,
              payload: {
                endpointurl: this.newStation.endpointurl,
              },
            };
            await this.updateIpAddressForParameters(object);
          }
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_UPDATING_STATION',
          });
        }
      }
      this.saving = false;
      this.endpointChanged = false;
      this.ipChanged = false;
    },
    async resetDialog() {
      this.$refs.form.resetValidation();
      this.sublineNew = { ...this.subline };
    },
  },
};
</script>

<style></style>
