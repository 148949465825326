<template>
  <v-dialog v-model="dialog" max-width="290">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-text="'$delete'" class="float-right" color="error"></v-icon>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Delete Subline?
        <v-spacer></v-spacer>
        <v-btn icon small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        Are you sure?
      </v-card-text>
      <v-card-text>
        This also removes all associated roadmaps and orders!
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="text-none" @click="btnDeleteSubline" :loading="deleting">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      newSubLine: {},
      deleting: false,
      default: false,
      dialog: false,
    };
  },
  props: {
    subline: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  computed: {
    ...mapState('productionLayoutMes', ['runningOrderList', 'roadMapDetailsRecord', 'subStations']),
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'deleteSubline',
      'getRunningOrder',
      'getRoadMapDetailsRecord',
      'getSubStationIdElement',
      'getSubStations',
      'inactiveElement',
    ]),
    async btnDeleteSubline() {
      let compareStation = [];
      await this.getRoadMapDetailsRecord();
      await this.getRunningOrder();
      if (this.runningOrderList && this.runningOrderList.length) {
        const getroadMapId = this.runningOrderList.map((rmi) => rmi.roadmapid);
        const roadmapList = this.roadMapDetailsRecord.filter(
          (rm) => rm.roadmapid === getroadMapId[0],
        );
        compareStation = roadmapList.filter((s) => s.sublineid === this.subline.id);
      }
      if (compareStation.length) {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'STATION_HAS_RUNNING_ORDER',
        });
        this.dialog = false;
      } else {
        // get plc parameter
        // const getSubStation = this.subStations
        //   .filter((s) => s.sublineid === this.subline.id);
        // const matchSubStation = getSubStation.forEach(async (item) => {
        //   const element = await this.getSubStationIdElement(item.id);
        //   const eleList = await this.inactiveElement(
        //     {
        //       elementId: element.id,
        //       status: 'INACTIVE',
        //     },
        //   );
        //   console.log(eleList);
        // });
        // await Promise.all([matchSubStation]);
        const gotRoadmap = this.roadMapDetailsRecord.filter((s) => s.sublineid === this.subline.id);
        const sublineObject = {
          id: this.subline.id,
          lineid: this.subline.lineid,
          roadmapid: null,
        };
        if (gotRoadmap.length > 0) {
          const deleteRoadmapId = gotRoadmap[0].roadmapid;
          sublineObject.roadmapid = deleteRoadmapId;
        }
        this.deleting = true;
        let deleted = false;
        deleted = await this.deleteSubline(sublineObject);
        if (deleted) {
          this.setAlert({
            show: true,
            type: 'success',
            message: 'SUBLINE_AND_DEPENDANT_DELETED',
          });
          this.deleting = false;
          this.dialog = false;
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_DELETING_SUBLINE',
          });
        }
      }
    },
  },
};
</script>
