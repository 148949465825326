<template>
  <v-dialog v-model="dialog" max-width="290">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-text="'$delete'" class="float-right" color="error"></v-icon>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Delete Substation?
        <v-spacer></v-spacer>
        <v-btn icon small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        Are you sure?
      </v-card-text>
      <div v-if="this.parameter.length > 0">
        <v-card-text>
          This also removes all associated parameter!
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="text-none" @click="btnDeleteSubstation" :loading="deleting">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      newSubstation: {},
      default: false,
      dialog: false,
      deleting: false,
      parameter: [],
    };
  },
  props: {
    substation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('productionLayoutMes', ['parametersList']),
    ...mapState('user', ['activeSite']),
  },
  created() {
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('productionLayoutMes', [
      'deleteSubstation',
      'getParameterList',
      'getSubStationIdElement',
    ]),
    async btnDeleteSubstation() {
      const elementsToDelete = await this.getSubStationIdElement({
        siteId: this.activeSite,
        substationid: this.substation.id,
      });
      const subStationObject = {
        id: this.substation.id,
        sublineid: this.substation.sublineid,
        lineid: this.substation.lineid,
      };
      if (elementsToDelete) {
        subStationObject.elements = elementsToDelete;
        subStationObject.status = 'INACTIVE';
      }
      let deleted = false;
      this.deleting = true;
      deleted = await this.deleteSubstation(subStationObject);
      if (deleted) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'SUB-STATION_AND_DEPENDANT_DELETED',
        });
        this.deleting = false;
        this.dialog = false;
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_DELETING_SUB-STATION',
        });
      }
      this.dialog = false;
    },
  },
};
</script>
