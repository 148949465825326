<template>
  <div>
    <v-data-table
      dense
      item-key="_id"
      class="transparent mt-2"
      :items="productionColumns"
      :headers="headers"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template #loading>
        Loading
      </template>
      <template #item.display="{ item, index }">
        <v-switch
          hide-details
          v-model="item.display"
          @change="ignoreColumn(item, index)"
          label="Show/Hide"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ColumnConfig',
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t('production.column'),
          value: 'text',
          width: '60%',
        },
        {
          text: this.$t('production.display'),
          value: 'display',
        },
      ],
    };
  },
  computed: {
    ...mapState('webApp', ['appSchema', 'activeAppId']),
    ...mapState('productionLog', [
      'assetId',
    ]),
    productionColumns() {
      let columns = [];
      const schemaDetails = this.appSchema.filter((schema) => schema.assetId === this.assetId);
      if (schemaDetails && schemaDetails.length && schemaDetails[0].productionHeaders
        && schemaDetails[0].productionHeaders.length) {
        columns = [...schemaDetails[0].productionHeaders];
      }
      return columns;
    },
  },
  methods: {
    ...mapMutations('productionLog', ['setLoading']),
    ...mapActions('webApp', ['getAppSchema', 'updateAppSchema']),
    async ignoreColumn(item, index) {
      const updatedSchema = [...this.appSchema];
      const schemaLength = updatedSchema.length;
      // eslint-disable-next-line
      for (let i = 0; i < schemaLength; i++) {
        if (updatedSchema[i].assetId === this.assetId) {
          updatedSchema[i].productionHeaders[index] = item;
        }
      }
      this.setLoading(true);
      await this.updateAppSchema({ appSchema: updatedSchema, saveForAll: false });
      await this.getAppSchema();
      this.setLoading(false);
    },
  },
};
</script>
